<template>
    <div class="vr-page">
      <div class="submenu" :class="{ close: openMenu==false }">
				<a class="item" :class="{ active : tab== 'nk' }" @click="changeTab('nk')" href="javascript:;">鸟瞰</a>
				<a class="item" :class="{ active : tab== 'jg' }" @click="changeTab('jg')" href="javascript:;">景观</a>
				<a class="item" :class="{ active : tab== 'yt' }" @click="changeTab('yt')" href="javascript:;">阳台</a>
				<a class="item" :class="{ active : tab== 'hx' }" @click="changeTab('hx')" href="javascript:;">户型</a>
				<div class="box"></div>
			</div>
			<div id="pano"></div>
			<div class="logo"></div>
			<div class="rightmenu" v-if="tab=='nk'">
				<a class="item" :class="{ active : showNum }" @click="toggleNum" href="javascript:;">楼栋编号</a>
				<a class="item" :class="{ active : showPT }" @click="togglePT" href="javascript:;">周边配套</a>
			</div>
			
			<div class="panomenu" :class="{'close':closepmenu}">
				<div class="scroll">
					<!-- <div class="jg thumbs">
						<div class="item" :class="{'active':scene == 'scene_jg1'}"><img src="/vtour/panos/jg1.tiles/thumb.jpg" @click="loadScene('scene_jg1')"><span>林下雅集</span></div>
						<div class="item" :class="{'active':scene == 'scene_jg2'}"><img src="/vtour/panos/jg2.tiles/thumb.jpg" @click="loadScene('scene_jg2')"><span>童梦空间</span></div>
						<div class="item" :class="{'active':scene == 'scene_jg3'}"><img src="/vtour/panos/jg3.tiles/thumb.jpg" @click="loadScene('scene_jg3')"><span>活力悦动</span></div>
						<div class="item" :class="{'active':scene == 'scene_jg4'}"><img src="/vtour/panos/jg4.tiles/thumb.jpg" @click="loadScene('scene_jg4')"><span>中庭</span></div>
						<div class="item" :class="{'active':scene == 'scene_jg5'}"><img src="/vtour/panos/jg5.tiles/thumb.jpg" @click="loadScene('scene_jg5')"><span>长者空间</span></div>
					</div> -->
					<div class="thumbs">
						<div class="item" v-for="(item, index) in scenelist" :key="index" :class="{'active':scene == item.name}"><img :src="item.thumburl" @click="loadScene(item.name)"><span>{{item.title}}</span></div>
					</div>
				</div>
				<a class="btn" href="javascript:;" @click="toggleMenu">
					<i class="iconfont icon-arrow"></i>
				</a>
			</div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

// var krpano = null;

export default {
  name:'VR',
  data(){
    const self = this
    return{
      // krpano: null,
      tab:'nk',
      scene: 'scene_nk1',
      scenelist: [],
      dan: 'night',
			closepmenu: false,
			showNum: false,
			showPT: false,
      hooks:{
        panoChanged() {
          self.panoChanged()
        },
        sceneChanged(scene){
          self.sceneChanged(scene)
        },
        clickHotspot(name) {
          self.clickHotspot(name)
        },
      }  
    }
  },
  computed: mapState([
    'openMenu'
  ]),
  mounted(){
      const { embedpano } = window
      embedpano({ xml:"/vtour/nk.xml", target:"pano", html5:"always", mobilescale:1.0, passQueryParameters:true, onready : this.krpano_onready_callback});
  },
  methods:{
    panoChanged(){
      console.log('panoChanged')
      this.scenelist = []
      const count = window.krpano.get('scene.count')
      for (let i = 0; i < count; i++) {
        const name = window.krpano.get(`scene[${i}].name`)
        const title = window.krpano.get(`scene[${i}].title`)
        const thumburl = window.krpano.get(`scene[${i}].thumburl`)
        this.scenelist.push({ name, title, thumburl })
      }
    },
    changeTab(id){
			if(id == 'hx') {
				this.$router.push({name:'House',params:{house:'c1',type:'vr'}})
			}
      this.tab = id
      this.loadpano('/vtour/'+id+'.xml')
    },
		toggleNum(){
			if (window.krpano){
				this.showNum = !this.showNum
				window.krpano.call("toggle_hotspots_number");
				if(this.showPT){
					this.showPT = false
					window.krpano.call("toggle_hotspots_pt");
				}
      }
		},
		togglePT(){
			this.showPT = !this.showPT
			window.krpano.call("toggle_hotspots_pt");
			if(this.showNum){
				this.showNum = false
				window.krpano.call("toggle_hotspots_number");
			}
		},
    krpano_onready_callback(krpano_interface){
      window.krpano = krpano_interface
      window.krpano.hooks = this.hooks
    },
    loadpano(xmlname){
      if (window.krpano){
        this.dan = 'night'
				// window.krpano.call("removelayer('skin_layer',true)")
				window.krpano.call("removelayer('map',true)")
        window.krpano.call("loadpanoscene(" + xmlname + ",'scene_"+this.tab+"1', null, MERGE, BLEND(0.5));");
      }
    },
    loadScene(scene){
      if (window.krpano){
        this.scene = scene
        window.krpano.call("loadscene('"+scene+"', null, MERGE, BLEND(0.5));");
      }
    },
    sceneChanged(scene){
      console.log(scene)
			this.scene = scene
			this.showNum = false
			this.showPT = false
    },
    toggleMenu(){
      console.log('toggleMenu')
      if(this.closepmenu){
        this.closepmenu = false
      }else{
        this.closepmenu = true
      }
    },
    changeDAN(){
      if(this.dan == 'day'){
        this.dan = 'night'
        this.loadScene('scene_nk1')
      }else{
        this.dan = 'day'
        this.loadScene('scene_nk2')
      }
    },
    clickHotspot(name){
      console.log(name)
    }
  },
  beforeDestroy () {
    const { removepano } = window
    if (window.krpano) {
      removepano(window.krpano.id)
      console.log('removepano')
      delete window.krpano
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
.vr-page{
	width: 100%;
	height: 100%;
	#pano{
		width: 100%;
		height: 100%;

		div{
			box-sizing: content-box;
		}
	}
	.logo{
		position: absolute;
		top: 50px;
		right: 10px;
		@include bg("logo2.png", 81px, 14px);
	}
	
	.rightmenu{
		position: absolute;
		top: 30vh;
		left: 0;
		z-index: 99;

		.item{
			display: block;
			border:1px solid #fff;
			border-left: 0;
			border-radius: 0 12px 12px 0;
			margin-bottom: 5px;
			color: #fff;
			padding: 5px 15px 5px 12px;
			background-color: rgba(0,0,0,0.6);

			&.active{
				background-color: rgba(164, 134, 104, .9)
			}
		}
	}
	.panomenu{
		position: absolute;
		bottom: 120px;
		left: 0;
		display: flex;
		align-items: center;
		background: rgba(0,0,0,0.8);
		padding: 5px;
		border-radius: 0 40px 40px 0;
		transform: translateX(0);
		transition: all .5s ease;
		z-index: 888;

		&.close{
			transform: translateX(calc(-100% + 40px));
			.btn{
				.iconfont{
					display: inline-block;
					transform: rotate(180deg);
				}
			}
		}

		.scroll{
			max-width: 260px;
			overflow-x: scroll;
			overflow-y: hidden;
			-webkit-overflow-scrolling:touch;
			touch-action: pan-x;

			.thumbs{
				position: relative;
				// display: inline-block;
				white-space: nowrap;

				&.jg{
					width: 370px;
				}
				
				.item{
					position: relative;
					// float: left;
					display: inline-block;
					width: 70px;
					margin: 0 2px;
					opacity: .5;
					border: 3px solid #fff;

					&.active{
						opacity: 1;
					}

					img{
						display: block;
						width: 100%;
						height: 50px;
						object-fit: cover;
					}

					span{
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						background: rgba(0,0,0,0.8);
						color: #fff;
						// margin: 2px;
						text-align: center;
						padding: 2px 0;
						font-size: 10px;
					}
				}
			}
		}

		.btn{
			color: #fff;
			padding: 10px 10px 10px 15px;
			.iconfont{
				font-size: 16px;
			}
		}
	}

	.dan-menu{
		position: absolute;
		top: 20vh;
		left: 0;
		z-index: 99;

		.item{
			display: block;
			border:1px solid #fff;
			border-left: 0;
			border-radius: 0 12px 12px 0;
			margin-bottom: 5px;
			color: #fff;
			padding: 2px 5px 2px 5px;
			background: rgba(0,0,0,0.8);

			.iconfont{
				font-size: 20px;
			}
		}
	}
}
</style>

